.point-list-wrapper {
  .point-list {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .point-list-aside {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;

      color: $primary;

      border-right-width: 1px;
      border-right-style: solid;
      border-color: $primary;

      &:after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;

        top: 50%;
        right: -5px;
        transform: translateY(-5px);
        border-radius: 50%;
        background-color: $primary;
      }

      .point-date {
        flex: 1;
        font-size: 2.75rem;
        line-height: 1.1;
      }

      .point-month {
        flex: 1;
        font-size: 1rem;
        text-transform: uppercase;
      }

      @each $color, $value in $theme-colors {
        &.point-list-#{$color} {
          color: $value;
          border-color: $value;

          &:after {
            background-color: $value;
          }
        }
      }
    }

    .point-list-content {
      flex: 1;
      margin-left: 2em;
      align-self: center;

      & > * {
        width: 100%;
      }
    }

    &.point-list-step {
      .point-list-aside {
        min-width: 54px;
        padding: 0.3rem 1rem 0.3rem 0;

        .point-date {
          flex: 0;
          font-size: 1.6rem;
          line-height: 1;

          // keep width in case of icon
          min-width: 32px;
        }

        .point-month {
          flex: 0;
          font-size: 0.8rem;
          text-transform: uppercase;
        }
      }

      .point-list-content {
        margin-left: 1em;
      }
    }
  }
  //Tablet horizontal / small desktop
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    &.point-list-step-wrapper {
      display: flex;
      flex-direction: row;

      .point-list-step {
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .point-list-aside {
          padding: $v-gap * 2 0;
          min-height: 65px;
          width: 100%;

          border-right-width: 0;
          border-bottom-width: 1px;
          border-bottom-style: solid;

          flex-direction: row;
          justify-content: center;
          align-items: flex-end;

          .point-month {
            margin-left: $v-gap;
            margin-bottom: -2px;
          }

          &:after {
            top: 100%;
            right: 50%;
            transform: translate(2px, -4px);
          }
        }

        .point-list-content {
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
