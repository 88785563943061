.password-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: $v-gap;
  z-index: 8;
  padding: 0 $spacer * 0.5;
  background-color: $white;
  .icon {
    fill: $gray-secondary;
  }
}
.password-meter {
  height: 4px;
  left: 10px;
  bottom: -6px;
  width: 100%;
  max-width: 180px;
  .col-3 {
    height: 4px;
  }
}
