//mobile
.it-carousel-wrapper {
  .owl-stage-outer {
    padding-top: $crs-margin-bottom;
    padding-bottom: 0;
  }

  // 3 col card
  &.it-carousel-landscape-abstract-3 {
    .owl-stage {
      left: -($crs-mob-live-padding + ($v-gap * 3));
    }
    &.it-full-carousel {
      .it-img-card {
        .owl-stage {
          left: unset;
        }
      }
      .owl-stage {
        left: unset;
      }
      .owl-dots {
        text-align: center;
      }
    }
    // with shadow
    .it-card-bg {
      .owl-stage-outer {
        border-right: none;
      }
      .owl-stage {
        left: -$crs-mob-live-padding;
      }
      .owl-dots {
        text-align: center;
        margin-top: 0;
      }
    }
    .owl-dots {
      text-align: center;
      margin-top: $v-gap * 2;
      margin-left: $crs-dots-margin-left;
      margin-right: $crs-dots-margin-left;
    }
    .it-img-card {
      .owl-stage {
        left: -$v-gap * 2;
      }
      .owl-dots {
        text-align: center;
      }
    }
  }
  // owl dots default
  .owl-dots {
    text-align: center;
    .owl-dot {
      width: $crs-dots-simension;
      height: $crs-dots-simension;
      background: $crs-dots-color;
      display: inline-block;
      opacity: 0.3;
      transition: all 0.3s;
      border-radius: 50px;
      margin: 0 $v-gap;
      &.active {
        opacity: 1;
      }
    }
  }
}
//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .it-carousel-wrapper {
    &.it-carousel-landscape-abstract {
      .owl-stage-outer {
        border-right: 3px solid #fff;
      }
    }
    &.it-carousel-landscape-abstract-3 {
      .it-img-card {
        .owl-stage {
          left: -$v-gap * 2;
        }
      }
      &.it-full-carousel {
        .it-img-card {
          .owl-stage {
            left: unset;
          }
        }
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-carousel-wrapper {
    // Landscape abstract
    &.it-carousel-landscape-abstract {
      .owl-stage {
        display: flex;
        .owl-item {
          display: flex;
          .it-single-slide-wrapper {
            height: 100%;
          }
        }
      }
      .owl-dots {
        position: absolute;
        left: 50%;
        margin-left: $v-gap * 5;
        right: 0;
        bottom: $crs-landcape-bottom;
        text-align: left;
        justify-content: left;
      }
    }
    // 3 col card
    &.it-carousel-landscape-abstract-3 {
      .owl-stage-outer {
        border-right: 1px solid #fff;
      }
      .owl-stage {
        left: inherit;
      }
      // with shadow
      .it-card-bg {
        .owl-stage {
          left: unset;
        }
        .owl-stage-outer {
          border-right: none;
        }
      }
      .owl-dots {
        text-align: left;
        margin-left: $crs-dots-margin-left-desk;
        margin-right: $crs-dots-margin-left-desk;
      }
    }
  }
}
