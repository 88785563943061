@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar {
    &.theme-dark-mobile {
      // navbar mobile
      .navbar-collapsable {
        // menu vrapper
        .menu-wrapper {
          background: $navigation-dark-bg-color;
          // heading megacolumn
          .nav-item.megamenu .dropdown-menu {
            .col-12:not(:first-child) {
              .it-heading-megacolumn {
                &:before {
                  background: $navigation-light-megamenu-separator-color;
                }
              }
            }
          }
          .navbar-nav {
            .dropdown-menu {
              .link-list {
                h3 {
                  color: $navigation-dark-text-color;
                }
              }
              // h3 separator
              .col-12:not(:first-child) {
                .link-list-wrapper {
                  h3 {
                    &:before {
                      background: $navigation-dark-separator-color;
                    }
                  }
                }
              }
            }
          }
        }
        /*lista menu*/
        .navbar-nav {
          // external link
          .it-external {
            .link-list-wrapper {
              .link-list {
                li.it-more {
                  a {
                    &:before {
                      background: $navigation-dark-separator-color;
                    }
                  }
                }
              }
            }
          }
          li {
            &.megamenu {
              // footer cta
              .it-megamenu-footer {
                background: $navigation-light-megamenu-footer-bg-color;
              }
              // vertical cta
              .dropdown-menu {
                .it-vertical {
                  background: $navigation-light-megamenu-footer-bg-color;
                  .link-list-wrapper {
                    .link-list {
                      li {
                        .divider {
                          background: $navigation-light-megamenu-separator-color;
                        }
                      }
                    }
                  }
                  // description content
                  &.it-description {
                    p {
                      color: $navigation-dark-text-color;
                    }
                    .description-content {
                      &:before {
                        background: $navigation-light-megamenu-separator-color;
                      }
                    }
                  }
                }
              }
            }
            a.nav-link {
              color: $navigation-dark-text-color;
              &.active {
                border-left-color: $navigation-dark-text-color;
              }
            }
          }
        }
      }
      //wrapper pulsante di chiusura menu
      .close-div {
        .close-menu {
          color: $navigation-dark-text-color;
        }
      }
      //dropdown
      .dropdown-menu {
        h3 {
          font-weight: 600;
          &.it-heading-megacolumn {
            color: $navigation-dark-text-color;
          }
        }
        .link-list-wrapper {
          ul {
            li {
              a {
                &.list-item.active {
                  color: $navigation-dark-text-color;

                  span {
                    color: $navigation-dark-text-color;
                  }
                }
                span {
                  color: $navigation-dark-text-color;
                }
                i {
                  color: $navigation-dark-text-color;
                }
                h3 {
                  color: $navigation-dark-text-color;
                }
              }
              h3 {
                color: $navigation-dark-text-color;
              }
              .divider {
                background: $navigation-dark-separator-color;
              }
            }
          }
        }
      }
    }
  }
}
/*
++++++++++++++++++++++++++++++Desktop light Theme+++++++++++++++++++++++++++++++++++++++++++++++++
*/
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar {
    &.theme-light-desk {
      background: $navigation-light-bg-color;
      box-shadow: $dropdown-box-shadow;
      // navbar mobile
      .navbar-collapsable {
        // menu vrapper
        .menu-wrapper {
          background: $navigation-light-bg-color;
          .navbar-nav {
            .dropdown-menu {
              .link-list {
                h3 {
                  color: $navigation-light-bg-color;
                }
              }
            }
          }
        }
        /*lista menu*/
        .navbar-nav {
          li {
            // megamenu variation
            &.megamenu {
              // external link
              .it-external {
                .link-list-wrapper {
                  .link-list {
                    li.it-more {
                      a {
                        &:before {
                          background: $navigation-light-megamenu-separator-color;
                        }
                      }
                    }
                  }
                }
              }
              a.nav-link {
                &:before {
                  background-color: $navigation-light-text-color;
                }
              }
              // footer cta
              .it-megamenu-footer {
                background: $navigation-light-megamenu-footer-bg-color;
              }
              // vertical cta
              .dropdown-menu {
                .it-vertical {
                  background: $navigation-light-megamenu-footer-bg-color;
                  .link-list-wrapper {
                    .link-list {
                      li {
                        .divider {
                          background: $navigation-light-megamenu-separator-color;
                        }
                      }
                    }
                  }
                }
              }
              // description content
              .it-description {
                p {
                  color: $navigation-light-bg-color;
                }
              }
            }

            a.nav-link {
              color: $navigation-light-text-color;
              &.active {
                border-bottom-color: $navigation-light-text-color;
              }
            }
          }
        }
      }
      //dropdown
      .dropdown-menu {
        background: $navigation-light-text-color;
        h3 {
          font-weight: 600;
          &.it-heading-megacolumn {
            color: $navigation-light-bg-color;
          }
        }
        &:before {
          background: $navigation-light-text-color;
        }
        .link-list-wrapper {
          ul {
            li {
              a {
                &.list-item.active {
                  color: $navigation-light-bg-color;

                  span {
                    color: $navigation-light-bg-color;
                  }
                }
                span {
                  color: $navigation-light-bg-color;
                }
                i {
                  color: $navigation-light-bg-color;
                }
                h3 {
                  color: $navigation-light-bg-color;
                }
              }
              h3 {
                color: $navigation-light-bg-color;
              }
              .divider {
                background: $navigation-light-separator-color;
              }
            }
          }
        }
      }
    }
  }
}
