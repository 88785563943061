//mobile
.it-carousel-wrapper {
  // splide pagination
  .splide__pagination {
    margin-top: $v-gap;
    margin-left: 0;
    padding-left: 0;
    button {
      width: $crs-dots-simension;
      height: $crs-dots-simension;
      background: $crs-dots-color;
      display: inline-block;
      opacity: 0.3;
      transition: all 0.3s;
      border-radius: 50px;
      margin: 0 $v-gap;
      padding: 0;
      box-shadow: none;
      border: none;
      &.is-active {
        opacity: 1;
      }
    }
  }
  .it-special-card {
    h5.card-title {
      margin-bottom: 0;
    }
  }
  margin: 0 auto;
  .it-header-block {
    h2 {
      font-size: $crs-heading-h-size;
      border-bottom: 1px solid $card-border-color;
      padding-bottom: $v-gap;
      margin-bottom: 0;
      letter-spacing: unset;
    }
  }
  .splide__track {
    padding-top: $crs-margin-bottom;
    padding-bottom: 0;
  }
  .card-wrapper {
    padding-bottom: $v-gap * 2;
  }
  // Landscape abstract
  &.it-carousel-landscape-abstract {
    .it-single-slide-wrapper {
      > a {
        position: relative;
        display: block;
      }
      .it-text-slider-wrapper-outside {
        .card {
          border-right: none;
          margin-right: 0;
          &:after {
            display: none;
          }
          .card-body {
            .card-signature {
              float: left;
              margin-bottom: 0;
              width: 60%;
            }
            a.read-more {
              float: right;
              position: inherit;
              bottom: inherit;
              margin-bottom: 0;
              width: 35%;
              justify-content: flex-end;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
  // 3 col card
  &.it-carousel-landscape-abstract-3 {
    .it-header-block {
      padding: 0 $crs-heading-h-padding;
    }
    margin: 0 auto;
    // with img
    .card.card-img {
      margin-left: 0;
      margin-right: 0;
    }
    // with shadow
    .it-card-bg {
      .card.card-bg {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-carousel-wrapper {
    // Landscape abstract
    &.it-carousel-landscape-abstract {
      max-width: #{map-get($container-max-widths, lg)};
      .splide__list {
        display: flex;
        .splide__slide {
          display: flex;
          .it-single-slide-wrapper {
            height: 100%;
          }
        }
      }
      .it-single-slide-wrapper {
        display: flex;
        flex-basis: 100%;
        width: 100%;
        > a {
          flex-basis: 50%;
          // img
          .img-responsive-wrapper {
            .img-responsive {
              padding-bottom: inherit;
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }
        .it-text-slider-wrapper-outside {
          flex-basis: 50%;
          .card {
            border-right: none;
            margin-right: 0;
            &:after {
              display: none;
            }
            .card-body {
              padding-left: $v-gap * 6;
              padding-bottom: $v-gap * 6;
              .card-signature {
                float: left;
                width: 60%;
              }
              a.read-more {
                float: right;
                position: inherit;
                bottom: inherit;
                margin-bottom: 0;
                width: 35%;
                justify-content: flex-end;
                line-height: 26px;
              }
            }
          }
        }
      }
      .splide__pagination {
        position: absolute;
        left: 50%;
        margin-left: $v-gap * 5;
        right: 0;
        bottom: $crs-landcape-bottom;
        text-align: left;
        justify-content: left;
      }
    }
    // 3 col card
    &.it-carousel-landscape-abstract-3 {
      max-width: #{map-get($container-max-widths, lg)};
      &.it-full-carousel {
        max-width: unset;
      }
      .it-header-block {
        padding: 0 $crs-heading-h-padding * 0.5;
      }
    }
  }
}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {
  .it-carousel-wrapper {
    // Landscape abstract
    &.it-carousel-landscape-abstract {
      max-width: #{map-get($container-max-widths, xl)};
    }
    // 3 col card
    &.it-carousel-landscape-abstract-3 {
      max-width: #{map-get($container-max-widths, xl)};
      &.it-full-carousel {
        max-width: unset;
      }
    }
  }
}
