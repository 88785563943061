.autocomplete__menu {
  border: none;
  width: 100%;
  position: absolute;
  top: 100%;
  z-index: 10;
  .autocomplete__hint,
  .autocomplete__option {
    padding: 12px 24px;
    background-color: #fff;
    border: none;
    font-size: 0.889rem;
    font-weight: 600;
    &:hover,
    &:focus {
      color: $primary;
    }
    &:focus {
      border: 2px solid $focus-outline-color;
    }
  }
}
